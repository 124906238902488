<script setup lang="ts">
import type { TableColumnType } from 'ant-design-vue'
import { formatDate } from '@vueuse/core'
import UserSelectModal from '../components/user-select-modal.vue'
import { queryDeviceControlLogApi } from '@/api/device-ability'
import { DeviceControlLogMap } from '@/constant/device-ability'

const selectUserModalVisible = ref(false)
const columns = [
  { title: '操作人', dataIndex: 'loginName' },
  { title: '操作方式', dataIndex: 'operation', customRender(opt: any) {
    return DeviceControlLogMap.get(opt.record.operation)
  } },
  { title: '操作时间', dataIndex: 'createdAt', customRender(opt: any) {
    return formatDate(new Date(opt.record.createdAt), 'YYYY-MM-DD HH:mm:ss')
  } },
  { title: '操作内容', dataIndex: 'detail' },
] as TableColumnType[]

const { state, onPageChange, onSearch } = usePageFetch({ apiFun: queryDeviceControlLogApi, columns, needPaging: true })

function onSelectedUser(userId: number) {
  onSearch({ userId })
}
</script>

<template>
  <page-container>
    <content-header title="设备控制记录" />
    <flex-content>
      <table-layout :need-header="true">
        <template #headerRight>
          <a-space size="middle">
            <a-button type="primary" @click="() => selectUserModalVisible = true">
              选择用户
            </a-button>
            <a-button @click="onSearch">
              重置
            </a-button>
          </a-space>
        </template>
        <template #content>
          <base-table v-bind="state" @page-change="onPageChange" />
        </template>
      </table-layout>
    </flex-content>
    <UserSelectModal v-model:open="selectUserModalVisible" @confirm="onSelectedUser" />
  </page-container>
</template>
